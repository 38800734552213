import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { AuthenService } from '../../services/authen.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor(
    private AuthenService: AuthenService,
    private Router: Router
  ) { }

  ngOnInit() {
    setTimeout(() => {
      this.onSetPermissions();
    }, 500);
  }

  btn_event: boolean = true;
  Permissions: any = [];
  onSetPermissions() {
    this.Permissions = this.AuthenService.getIU()
  }

}
