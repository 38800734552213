import { Component, OnInit } from '@angular/core';
import { EmployeeService } from '../../../services/employee.service'
import { DynamicDialogRef } from 'primeng/api';

@Component({
  selector: 'app-search-employee',
  templateUrl: './search-employee.component.html',
  styleUrls: ['./search-employee.component.css']
})
export class SearchEmployeeComponent implements OnInit {

  constructor(
    public EmployeeService: EmployeeService,
    public ref: DynamicDialogRef,
  ) { }

  loading: boolean;
  listItem: any [];

  ngOnInit() {
    this.loading = true;
    this.onLoad();
  }

  onLoad(){
    this.EmployeeService.GetEmployee().then( 
      cust => {
      this.listItem = cust;
      this.loading = false;
    }); 
  }

  onSelect(items){
    this.ref.close(items);
  }

  onSelectdbc(items){
    this.ref.close(items);
  }

}
