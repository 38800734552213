import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})

export class ConfigServerService {
  // Inet
  // public URL_API: string = "http://203.154.66.198/SOAPI/api";
  public URL_API: string = "https://apisoacldi.mnhappysoft.com/api";

  // Local
  // public URL_API: string = "http://localhost:59043/api";

  // Prod
  // public URL_API: string = "https://soreport.rkcricesale.com/api";
}
