import { ConfigServerService } from './../../services/config/config-server.service';
import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, Params } from '@angular/router';


@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css']
})
export class ReportComponent implements OnInit {

  constructor(
    public DomSanitizer: DomSanitizer,
    private ActivatedRoute: ActivatedRoute,
    private ConfigServerService: ConfigServerService
  ) {
    this.progressBar = true;
  }

  @Input() url: string = this.ConfigServerService.URL_API
  urlSafe: SafeResourceUrl;

  myParam: string;
  progressBar: boolean = false;


  ngOnInit() {

    let query = this.ActivatedRoute.snapshot.queryParams

    console.log(`${this.url}/Report/${query['report']}/${query['id']}`)

    this.urlSafe = this.DomSanitizer.bypassSecurityTrustResourceUrl(`${this.url}/Report/${query['report']}/${query['id']}`);

    console.log(this.urlSafe)
    setTimeout(() => {
      this.progressBar = false;
    }, 1500);
  }
}
