import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ConfigServerService } from './config/config-server.service'

@Injectable({
  providedIn: 'root'
})
export class SaleOrderService {

  constructor(
    private http: HttpClient,
    private ConfigServerService: ConfigServerService
  ) { }

  GetAllOrder() {
    let corp_id = localStorage.getItem('corp_id')
    let queryParams = new HttpParams().append('corp_id', corp_id);
    return this.http
      .get(`${this.ConfigServerService.URL_API}/Orders/GetOrder`, {params:queryParams})
      .toPromise()
      .then(res => res as any[])
  }

  GetOrderByID(so_id) {
    let corp_id = localStorage.getItem('corp_id')
    let queryParams = new HttpParams().append('corp_id', corp_id);
    return this.http
      .get(`${this.ConfigServerService.URL_API}/Orders/GetOrderby/${so_id}`, {params:queryParams})
      .toPromise()
      .then(res => res as any[])
  }

  PostSaleOrder(data) {
    let corp_id = localStorage.getItem('corp_id')
    let queryParams = new HttpParams().append('corp_id', corp_id);
    return this.http
      .post(`${this.ConfigServerService.URL_API}/Orders/InsSaleOrder`, data, {params:queryParams})
      .toPromise()
      .then(res => res as any[])
  }

  PutSaleOrder(so_id, data) {
    let corp_id = localStorage.getItem('corp_id')
    let queryParams = new HttpParams().append('corp_id', corp_id);
    return this.http
      .put(`${this.ConfigServerService.URL_API}/Orders/UpdSaleOrder/${so_id}`, data, {params:queryParams})
      .toPromise()
      .then(res => res as any[])
  }

  DelSaleOrder(so_id) {
    let corp_id = localStorage.getItem('corp_id')
    let queryParams = new HttpParams().append('corp_id', corp_id);
    return this.http
      .delete(`${this.ConfigServerService.URL_API}/Orders/DelSaleOrder/${so_id}`, {params:queryParams})
      .toPromise()
      .then(res => res as any[])
  }

  GetCustomerGroupsDiscount(data) {
    let corp_id = localStorage.getItem('corp_id')
    let queryParams = new HttpParams().append('corp_id', corp_id);
    return this.http
      .post(`${this.ConfigServerService.URL_API}/Orders/CustomerGroupsDiscount`, data, {params:queryParams})
      .toPromise()
      .then(res => res as any[])
  }

  PostPriceUnit(data) {
    let corp_id = localStorage.getItem('corp_id')
    let queryParams = new HttpParams().append('corp_id', corp_id);
    return this.http
      .post(`${this.ConfigServerService.URL_API}/Orders/PriceUnit`, data, {params:queryParams})
      .toPromise()
      .then(res => res as any[])
  }

  PostReqDiscount(data) {
    let corp_id = localStorage.getItem('corp_id')
    let queryParams = new HttpParams().append('corp_id', corp_id);
    return this.http
      .post(`${this.ConfigServerService.URL_API}/Orders/INS_ReqDiscount`, data, {params:queryParams})
      .toPromise()
      .then(res => res as any[])
  }

  GetReqDiscountBykey(page: string, key: string,) {
    let corp_id = localStorage.getItem('corp_id')
    let queryParams = new HttpParams().append('corp_id', corp_id);
    return this.http
      .get(`${this.ConfigServerService.URL_API}/Orders/GetReqDiscount/${page}/${key}`, {params:queryParams})
      .toPromise()
      .then(res => res as any[])
  }

  PutReqDiscount(id: string, data) {
    let corp_id = localStorage.getItem('corp_id')
    let queryParams = new HttpParams().append('corp_id', corp_id);
    return this.http
      .put(`${this.ConfigServerService.URL_API}/Orders/UPD_ReqDiscount/${id}`, data, {params:queryParams})
      .toPromise()
      .then(res => res as any[])
  }

  GetFreeGift(data) {
    let corp_id = localStorage.getItem('corp_id')
    let queryParams = new HttpParams().append('corp_id', corp_id);
    return this.http
      .post(`${this.ConfigServerService.URL_API}/Orders/FreeGift`, data, {params:queryParams})
      .toPromise()
      .then(res => res as any[])
  }

  GetPromotion(data) {
    let corp_id = localStorage.getItem('corp_id')
    let queryParams = new HttpParams().append('corp_id', corp_id);
    return this.http
      .post(`${this.ConfigServerService.URL_API}/Orders/CheckPromotion`, data, {params:queryParams})
      .toPromise()
      .then(res => res as any[])
  }
}
