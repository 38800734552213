import { Component, OnInit } from '@angular/core';
import { CustomerGroupService } from '../../../services/customer-group.service'
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/api';


@Component({
  selector: 'app-search-customer-group',
  templateUrl: './search-customer-group.component.html',
  styleUrls: ['./search-customer-group.component.css']
})
export class SearchCustomerGroupComponent implements OnInit {

  constructor(
    private CustomerGroupService: CustomerGroupService,
    public ref: DynamicDialogRef, 
    public config: DynamicDialogConfig
  ) { }

 
  loading: boolean;
  listCustomerGroup: any [];

  ngOnInit() {
    this.loading = true;
    this.onLoad();
  }

  onLoad(){
    this.CustomerGroupService.GetCustomerGroup().then(listCustomerGroup => {
      this.listCustomerGroup = listCustomerGroup;
      this.loading = false;
    }); 
  }

  onSelect(items){
    this.ref.close(items);
  }

  onSelectdbc(items){
    this.ref.close(items);
  }

}
