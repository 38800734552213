import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ConfigServerService } from './config/config-server.service'

@Injectable({
  providedIn: 'root'
})
export class BankService {

  constructor(
    private http: HttpClient,
    private CongigServerService: ConfigServerService
  ) { }

  GetBank() {
    let corp_id = localStorage.getItem('corp_id')
    let queryParams = new HttpParams().append('corp_id', corp_id);
    return this.http
      .get(`${this.CongigServerService.URL_API}/Bank/GetBank`, {params:queryParams})
      .toPromise()
      .then(res => res as any[])
  }

  GetBankBook() {
    let corp_id = localStorage.getItem('corp_id')
    let queryParams = new HttpParams().append('corp_id', corp_id);
    return this.http
      .get(`${this.CongigServerService.URL_API}/Bank/GetBankBook`, {params:queryParams})
      .toPromise()
      .then(res => res as any[])
  }
}
