import { Component, OnInit } from '@angular/core';
import { ProductService } from '../../../services/product.service'
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/api';

@Component({
  selector: 'app-search-product',
  templateUrl: './search-product.component.html',
  styleUrls: ['./search-product.component.css']
})
export class SearchProductComponent implements OnInit {

  constructor(
    private ProductService: ProductService,
    public ref: DynamicDialogRef, 
    public config: DynamicDialogConfig
  ) { }

 
  loading: boolean;
  listProduct: any [];

  ngOnInit() {
    this.loading = true;
    this.onLoadProdicts();
  }

  onLoadProdicts(){
    this.ProductService.GetProduct().then(listProduct => {
      this.listProduct = listProduct;
      this.loading = false;
    }); 
  }

  onSelect(items){
    this.ref.close(items);
  }

  onSelectdbc(items){
    this.ref.close(items);
  }
}
