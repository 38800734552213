import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ConfigServerService } from './config/config-server.service'

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  constructor(
    private http: HttpClient,
    private Config: ConfigServerService
  ) { }

  GetCustomer() {
    let corp_id = localStorage.getItem('corp_id')
    let queryParams = new HttpParams().append('corp_id', corp_id);
    return this.http
      .get(`${this.Config.URL_API}/Customer/Getcustomer`, {params:queryParams})
      .toPromise()
      .then(res => res as any[])
  }

  GetCustomerById(id: number) {
    let corp_id = localStorage.getItem('corp_id')
    let queryParams = new HttpParams().append('corp_id', corp_id);
    return this.http
      .get(`${this.Config.URL_API}/Customer/Getcustomerby/${id}`, {params:queryParams})
      .toPromise()
      .then(res => res as any[])
  }

  PostCustomer(data) {
    let corp_id = localStorage.getItem('corp_id')
    let queryParams = new HttpParams().append('corp_id', corp_id);
    return this.http
      .post(`${this.Config.URL_API}/Customer/Inscustomer`, data, {params:queryParams})
      .toPromise()
      .then(res => res as any[])
  }

  PutCustomer(id: number, data) {
    let corp_id = localStorage.getItem('corp_id')
    let queryParams = new HttpParams().append('corp_id', corp_id);
    return this.http
      .put(`${this.Config.URL_API}/Customer/Updcustomer/${id}`, data, {params:queryParams})
      .toPromise()
      .then(res => res as any[])
  }

  DelCustomer(id: number) {
    let corp_id = localStorage.getItem('corp_id')
    let queryParams = new HttpParams().append('corp_id', corp_id);
    return this.http
      .delete(`${this.Config.URL_API}/Customer/Delcustomer/${id}`, {params:queryParams})
      .toPromise()
      .then(res => res as any[])
  }
}
