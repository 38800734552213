import { Component, OnInit } from '@angular/core';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/api';
import { SaleOrderService } from '../../../services/sale-order.service'

@Component({
  selector: 'app-search-custgroupdiscount',
  templateUrl: './search-custgroupdiscount.component.html',
  styleUrls: ['./search-custgroupdiscount.component.css']
})
export class SearchCustgroupdiscountComponent implements OnInit {

  constructor(
    public SaleOrderService: SaleOrderService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig
  ) { }

  loading: boolean;
  listItem: any[];

  ngOnInit() {
    this.loading = true;
    let item = this.config.data
    this.onLoadProdicts(item);
  }

  onLoadProdicts(item) {
    this.SaleOrderService.GetCustomerGroupsDiscount(item).then(
      price => {
        if (price['message']){
          this.listItem = []
          this.loading = false;
          return;
        }
        this.listItem = price;  
        this.loading = false; 
      });
  }

  onSelect(items) {
    this.ref.close(items);
  }

  onSelectdbc(items) {
    this.ref.close(items);
  }

}
