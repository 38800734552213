import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ConfigServerService } from './config/config-server.service'

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private http: HttpClient,
    private ConfigServerService: ConfigServerService
  ) { }


  GetAllPermissions() {
    let corp_id = localStorage.getItem('corp_id')
    let queryParams = new HttpParams().append('corp_id', corp_id);
    return this.http
      .get(`${this.ConfigServerService.URL_API}/AccountPermission/getAccountPermission`, {params:queryParams})
      .toPromise()
      .then(res => res as any[])
  }

  GetAllPermissionsByID(emp_id) {
    let corp_id = localStorage.getItem('corp_id')
    let queryParams = new HttpParams().append('corp_id', corp_id);
    return this.http
      .get(`${this.ConfigServerService.URL_API}/AccountPermission/getAccountPermissionBy/${emp_id}`, {params:queryParams})
      .toPromise()
      .then(res => res as any[])
  }

  GetEmp() {
    let corp_id = localStorage.getItem('corp_id')
    let queryParams = new HttpParams().append('corp_id', corp_id);
    return this.http
      .get(`${this.ConfigServerService.URL_API}/AccountPermission/GetEmployee`, {params:queryParams})
      .toPromise()
      .then(res => res as any[])
  }


  INSPermissions(obj) {
    let corp_id = localStorage.getItem('corp_id')
    let queryParams = new HttpParams().append('corp_id', corp_id);
    return this.http
      .post(`${this.ConfigServerService.URL_API}/AccountPermission/InsAccountPermission/`, obj, {params:queryParams})
      .toPromise()
      .then(res => res as any[])
  }

  UpdPermissions(emp_id, obj) {
    let corp_id = localStorage.getItem('corp_id')
    let queryParams = new HttpParams().append('corp_id', corp_id);
    return this.http
      .put(`${this.ConfigServerService.URL_API}/AccountPermission/updAccountPermission/${emp_id}`, obj, {params:queryParams})
      .toPromise()
      .then(res => res as any[])
  }

}
