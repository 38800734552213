export class conf {
    public static CALth = {
        firstDayOfWeek: 0,
        dayNames: ["อาทิตย์", "จันทร์", "อังคาร", "พุธ", "พฤหัส", "ศุกร์", "เสาร์"],
        dayNamesShort: ["อา", "จ", "อ", "พ", "พฤ", "ศ", "ส"],
        dayNamesMin: ["อา", "จ", "อ", "พ", "พฤ", "ศ", "ส"],
        monthNames: ["มกราคม", "กุมภาพันธ์", "มีนาคม", "เมษายน", "พฤษภาคม", "มิถุนายน", "กรกฏาคม", "สิงหาคม", "กันยายน", "ตุลาคม", "พฤศจิกายน", "ธันวาคม"],
        monthNamesShort: ["ม.ค.", "ก.พ.", "มี.ย.", "เม.ย.", "พ.ค.", "มิ.ย.", "ก.ค.", "ส.ค.", "ก.ย.", "ต.ค.", "พ.ย", "ธ.ค."],
        today: 'วันนี้',
        clear: 'ปิด'
    };

    public static ErrorMsg = "There is a problem with the service. Plase try again leter.";
}