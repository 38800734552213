import { Component, Input, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { MenuItem, ScrollPanel } from 'primeng/primeng';
import { AppComponent } from '../../app.component';
import { ContentComponent } from '../content/content.component'
import { AuthenService, IUserLogin } from '../../services/authen.service'
import { UserService } from '../../services/user.service';
import { async } from '@angular/core/testing';

@Component({
   selector: 'app-menu',
   templateUrl: './menu.component.html',
   styleUrls: ['./menu.component.css']
})
export class AppMenuComponent implements OnInit, AfterViewInit {


   @Input() reset: boolean;

   model: any[];

   @ViewChild('layoutMenuScroller', { static: true }) layoutMenuScrollerViewChild: ScrollPanel;

   constructor(
      public app: ContentComponent,
      private AuthenService: AuthenService,
      private Router: Router,
      private UserService: UserService
   ) {

   }

   async onBuildMenu(obt_menu) {
      let permis = obt_menu;
      this.model = []
      /**
       * permis Status
       * 0 close
       * 1 add/edit/del
       * 2 read only
       *
       * customer_menu = ผู้รับสินค้า
       * employee_menu = พนักงาน
       * good_menu = สินค้า
       * promotion_menu = โปรโมชั่น
       * coupon_menu = คูปอง
       * gift_menu = ของแถม
       * pricelevel_menu = pricelevel
       * 
       * invoice_menu = invoice
       * payment_menu = รับชำระ
       * quotation_menu = เสนอราคา
       * customer_m_menu = เปิดผู้รับสินค้ามือถือ
       * saleorder_menu = ใบสั่งขาย
       * shipment_menu = จัดของขึ้นรถ
       * 
       * reqdiscount_menu = ขอส่วนลด
       * confpayment_menu = ตรวจสอบการรับชำระ
       * permission_menu = กำหนดสิท
       * 
       * payment_report = รายงานการรับชำระ
       * saleorder_report = รายงานการขาย
       * salearea_report = รายงานสรุปคำสั่งซื้อ
       * 
       */

      //#region Master menu
      let Master_Menu = []
      if (permis['customer_menu'] != 0) {
         Master_Menu.push(
            {
               label: ' ผู้รับสินค้า', icon: 'fas fa-users',
               routerLink: ['/MasterData', 'Customer']
            }
         )
      }
      if (permis['employee_menu'] != 0) {
         Master_Menu.push(
            {
               label: ' พนักงาน', icon: 'fas fa-user-friends',
               routerLink: ['/MasterData', 'Employee']
            }
         )
      }
      if (permis['good_menu'] != 0) {
         Master_Menu.push(
            {
               label: ' สินค้า', icon: 'fas fa-seedling',
               routerLink: ['/MasterData', 'Product']
            }
         )
      }
      if (permis['promotion_menu'] != 0) {
         Master_Menu.push(
            {
               label: ' โปรโมชั่น', icon: 'fas fa-percent',
               routerLink: ['/MasterData', 'Promotion']
            }
         )
      }
      // if (permis['coupon_menu'] != 0) {
      //    Master_Menu.push(
      //       {
      //          label: ' คูปอง', icon: 'fas fa-tag',
      //          routerLink: ['/MasterData', 'Coupon']
      //       }
      //    )
      // }
      if (permis['gift_menu'] != 0) {
         Master_Menu.push(
            {
               label: ' ของแถม', icon: 'fas fa-gifts',
               routerLink: ['/MasterData', 'Gift']
            }
         )
      }
      if (permis['pricelevel_menu'] != 0) {
         Master_Menu.push(
            {
               label: ' Price Level', icon: 'fas fa-layer-group',
               routerLink: ['/MasterData', 'Pricelevel']
            }
         )
      }
      //#endregion

      //#region Sale Menu
      let Sale_Menu = []
      // if (permis['quotation_menu'] != 0) {
      //    Sale_Menu.push(
      //       { label: ' ใบเสนอราคา', icon: 'fas  fa-clipboard fa-lg', routerLink: ['/Sale', 'Quotation'] }
      //    )
      // }
      if (permis['saleorder_menu'] != 0) {
         Sale_Menu.push(
            { label: ' ใบสั่งขาย', icon: 'fas fa-clipboard fa-lg', routerLink: ['/Sale', 'SaleOrder'] }
         )
      }
      // if (permis['invoice_menu'] != 0) {
      //    Sale_Menu.push(
      //       { label: ' ใบแจ้งหนี้', icon: 'fas fa-file-invoice fa-lg', routerLink: ['/Sale', 'Invoice'] }
      //    )
      // }
      // if (permis['payment_menu'] != 0) {
      //    // Sale_Menu.push(
      //    //     { label: ' รับชำระ', icon: 'fas fa-money-bill-wave fa-lg', routerLink: ['/Sale', '_Payment', 'Add', '0'] }
      //    // )
      //    Sale_Menu.push(
      //       { label: ' ข้อมูลรับชำระ', icon: 'fas fa-money-bill-wave fa-lg', routerLink: ['/Sale', 'Payment'] }
      //    )
      // }
      // if (permis['shipment_menu'] != 0) {
      //    Sale_Menu.push(
      //       { label: ' จัดของขึ้นรถ', icon: 'fas fa-truck', routerLink: ['/Sale', 'Shipment'] }
      //    )
      // }
      // Sale_Menu.push(
      //    { label: ' ประวัติการสั่งซื้อ', icon: 'fas  fa-history', routerLink: ['/Sale', 'OrderHistory'] }
      // )

      //#endregion

      //#region  Admin Menu
      let Admin_Menu = []
      // if (permis['reqdiscount_menu'] != 0) {
      //    Admin_Menu.push(
      //       {
      //          label: ' ขอมูลการขอส่วนลด', icon: 'fab fa-line ',
      //          routerLink: ['/Admin', '_RequestDiscount']
      //       }
      //    )
      // }
      // if (permis['confpayment_menu'] != 0) {
      //    Admin_Menu.push(
      //       {
      //          label: ' ตรวจสอบการรับชำระ', icon: 'fas fa-tags',
      //          routerLink: ['/Admin', 'ConfirmPayment']
      //       }
      //    )
      // }
      if (permis['permission_menu'] != 0) {
         Admin_Menu.push(
            {
               label: ' กำหนดสิทธิ์', icon: 'fas fa-key',
               routerLink: ['/Admin', 'Permissions']
            }
         )
      }
      //#endregion

      //#region  Report Menu
      let Report_Menu = []

      if (permis['reqdiscount_report'] != 0) {
         Report_Menu.push(
            {
               label: ' รายงานการขอส่วนลด', icon: 'far fa-circle',
               routerLink: ['/Report', 'RequestDiscount']
            }
         )
      }

      if (permis['payment_report'] != 0) {
         Report_Menu.push(
            {
               label: ' รายงานการชำระหนี้', icon: 'far fa-circle',
               routerLink: ['/Report', 'Payment']
            }
         )
      }
      if (permis['saleorder_report'] != 0) {
         Report_Menu.push(
            {
               label: ' รายงานสรุปคำสั่งซื้อ', icon: 'far fa-circle',
               routerLink: ['/Report', 'SaleOrder']
            }
         )
      }
      if (permis['salearea_report'] != 0) {
         Report_Menu.push(
            {
               label: ' รายงานสรุปน้ำหนักรวมแยกตามสายการวิ่ง', icon: 'far fa-circle',
               routerLink: ['/Report', 'SaleArea']
            }
         )
      }
      if (permis['invshipment_report'] != 0) {
         Report_Menu.push(
            {
               label: ' รายงาน Order ตกค้าง', icon: 'far fa-circle',
               routerLink: ['/Report', 'InvoiceShipment']
            }
         )
      }
      //#endregion

      let Menu_Home = { label: 'หน้าหลัก', icon: 'fas fa-home fa-lg', routerLink: ['/'] }
      let Menu_Master = { label: 'ข้อมูลพื้นฐาน', icon: 'fas fa-database fa-lg', items: Master_Menu }
      let Menu_Sale = { label: 'Sale', icon: 'fas fa-shopping-cart fa-lg', items: Sale_Menu }
      let Menu_Admin = { label: 'Admin', icon: 'fas fa-user-shield fa-lg', items: Admin_Menu }
      let Memu_Report = { label: 'รายงาน', icon: 'far fa-file-alt fa-lg', items: Report_Menu }
      let Menu_Logout = { label: ' ออกจากระบบ', icon: 'fas fa-sign-out-alt fa-lg', command: (event: Event) => { this.onlogout() } }

      this.model.push(Menu_Home)
      if (Master_Menu.length != 0) {
         this.model.push(Menu_Master)
      }
      if (Sale_Menu.length != 0) {
         this.model.push(Menu_Sale)
      }
      // if (Report_Menu.length != 0) {
      //    this.model.push(Memu_Report)
      // }
      if (Admin_Menu.length != 0) {
         this.model.push(Menu_Admin)
      }
      // this.model.push(Menu_Logout)

      return await this.model
   }

   ngOnInit() {
      if (this.AuthenService.getIU() != undefined) {
         let a = this.onBuildMenu(this.AuthenService.getIU())
         return;
      } else {
         let empID = localStorage.getItem('emp_id')
         if (empID) {
            this.UserService.GetAllPermissionsByID(empID).then(
               async res => {
                  let permis: IUserLogin[] = res[0]
                  // let a = this.AuthenService.setUser(res[0])
                  this.AuthenService.setIU(res[0])
                  await this.onBuildMenu(permis)
               }
            ).catch(
               res => {
                  console.log(res)
               }
            )
         }
      }
   }

   ngAfterViewInit() {
      setTimeout(() => { this.layoutMenuScrollerViewChild.moveBar(); }, 100);
   }

   changeTheme(theme) {
      const themeLink: HTMLLinkElement = document.getElementById('theme-css') as HTMLLinkElement;
      themeLink.href = 'assets/theme/theme-' + theme + '.css';
   }
   changeLayout(layout) {
      const layoutLink: HTMLLinkElement = document.getElementById('layout-css') as HTMLLinkElement;
      layoutLink.href = 'assets/layout/css/layout-' + layout + '.css';
   }

   onMenuClick() {
      if (!this.app.isHorizontal()) {
         setTimeout(() => {
            this.layoutMenuScrollerViewChild.moveBar();
         }, 450);
      }

      this.app.onMenuClick();
   }

   //#region My Function
   onlogout() {
      this.AuthenService.clearAuthenticated();
      this.Router.navigate(['/login']);
   }
   //#endregion

}

@Component({
   /* tslint:disable:component-selector */
   selector: '[app-submenu]',
   /* tslint:enable:component-selector */
   template: `
        <ng-template ngFor let-child let-i="index" [ngForOf]="(root ? item : item.items)">
            <li [ngClass]="{'active-menuitem': isActive(i)}">
                <a [href]="child.url||'#'" (click)="itemClick($event,child,i)" *ngIf="!child.routerLink"
                   [attr.tabindex]="!visible ? '-1' : null" [attr.target]="child.target" (mouseenter)="onMouseEnter(i)">
                    <i [ngClass]="child.icon"></i>
                    <span>{{child.label}}</span>
                    <i class="fa fa-fw fa-angle-down layout-submenu-toggler" *ngIf="child.items"></i>
                    <span class="menuitem-badge" *ngIf="child.badge" [ngClass]="child.badgeStyleClass">{{child.badge}}</span>
                </a>

                <a (click)="itemClick($event,child,i)" *ngIf="child.routerLink"
                    [routerLink]="child.routerLink" routerLinkActive="active-menuitem-routerlink"
                    [routerLinkActiveOptions]="{exact: true}" [attr.tabindex]="!visible ? '-1' : null" [attr.target]="child.target"
                    (mouseenter)="onMouseEnter(i)">
                    <i [ngClass]="child.icon"></i>
                    <span>{{child.label}}</span>
                    <i class="fa fa-fw fa-angle-down" *ngIf="child.items"></i>
                    <span class="menuitem-badge" *ngIf="child.badge" [ngClass]="child.badgeStyleClass">{{child.badge}}</span>
                </a>
                <ul app-submenu [item]="child" *ngIf="child.items" [visible]="isActive(i)" [reset]="reset" [parentActive]="isActive(i)"
                    [@children]="isActive(i) ? 'visible' : 'hidden'"></ul>
            </li>
        </ng-template>
    `,
   animations: [
      trigger('children', [
         state('visible', style({
            height: '*'
         })),
         state('hidden', style({
            height: '0px'
         })),
         transition('visible => hidden', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
         transition('hidden => visible', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
      ])
   ]
})
export class AppSubMenuComponent {

   @Input() item: MenuItem;

   @Input() root: boolean;

   @Input() visible: boolean;

   _reset: boolean;

   _parentActive: boolean;

   activeIndex: number;

   hover: boolean;

   constructor(public app: ContentComponent, public router: Router, public location: Location, public appMenu: AppMenuComponent) { }

   itemClick(event: Event, item: MenuItem, index: number) {
      if (this.root) {
         this.app.menuHoverActive = !this.app.menuHoverActive;
      }

      // avoid processing disabled items
      if (item.disabled) {
         event.preventDefault();
         return true;
      }

      // activate current item and deactivate active sibling if any
      if (item.routerLink || item.items || item.command || item.url) {
         this.activeIndex = (this.activeIndex === index) ? null : index;
      }

      // execute command
      if (item.command) {
         item.command({ originalEvent: event, item });
      }

      // prevent hash change
      if (item.items || (!item.url && !item.routerLink)) {
         setTimeout(() => {
            this.appMenu.layoutMenuScrollerViewChild.moveBar();
         }, 450);
         event.preventDefault();
      }

      // hide menu
      if (!item.items) {
         if (this.app.menuMode === 'horizontal') {
            this.app.resetMenu = true;
         } else {
            this.app.resetMenu = false;
         }
         if (this.app.isMobile() || this.app.menuMode === 'overlay' || this.app.menuMode === 'popup') {
            this.app.menuActive = false;
         }

         this.app.menuHoverActive = false;
      }
   }

   onMouseEnter(index: number) {
      if (this.root && this.app.menuHoverActive && this.app.isHorizontal()
         && !this.app.isMobile() && !this.app.isTablet()) {
         this.activeIndex = index;
      }
   }

   isActive(index: number): boolean {
      return this.activeIndex === index;
   }

   @Input() get reset(): boolean {
      return this._reset;
   }

   set reset(val: boolean) {
      this._reset = val;

      if (this._reset && (this.app.menuMode === 'horizontal')) {
         this.activeIndex = null;
      }
   }

   @Input() get parentActive(): boolean {
      return this._parentActive;
   }

   set parentActive(val: boolean) {
      this._parentActive = val;

      if (!this._parentActive) {
         this.activeIndex = null;
      }
   }
}
