import { Component, OnInit } from '@angular/core';
import { DynamicDialogRef } from 'primeng/api';
import { BankService} from '../../../services/bank.service'
@Component({
  selector: 'app-search-bankbook',
  templateUrl: './search-bankbook.component.html',
  styleUrls: ['./search-bankbook.component.css']
})
export class SearchBankbookComponent implements OnInit {

  constructor(
    private BankService: BankService,
    public ref: DynamicDialogRef,
  ) { }

  loading: boolean;
  listItem: any [];

  ngOnInit() {
    this.loading = true;
    this.onLoadProdicts();
  }

  onLoadProdicts(){
    this.BankService.GetBankBook().then( 
      zone => {
      this.listItem = zone;
      this.loading = false;
    }); 
  }

  onSelect(items){
    this.ref.close(items);
  }

  onSelectdbc(items){
    this.ref.close(items);
  }
}
