import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ConfigServerService } from './config/config-server.service'

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  constructor(
    private http: HttpClient,
    private ConfigServerService: ConfigServerService
  ) { }

  GetProduct() {
    let corp_id = localStorage.getItem('corp_id')
    let queryParams = new HttpParams().append('corp_id', corp_id);
    return this.http
      .get(`${this.ConfigServerService.URL_API}/Goods/GetGoods`, {params:queryParams})
      .toPromise()
      .then(res => res as any[])
  }

  GetProductByID(ID) {
    let corp_id = localStorage.getItem('corp_id')
    let queryParams = new HttpParams().append('corp_id', corp_id);
    return this.http
      .get(`${this.ConfigServerService.URL_API}/Goods/GetGoodsby/${ID}`, {params:queryParams})
      .toPromise()
      .then(res => res as any[])
  }

  PutProduct(ID: number, data, good_stdcost: number) {
    let corp_id = localStorage.getItem('corp_id')
    console.log(data)
    let queryParams = new HttpParams().append('corp_id', corp_id);
    queryParams = new HttpParams().append('good_stdcost', good_stdcost.toString());
    return this.http
      .put(`${this.ConfigServerService.URL_API}/Goods/UpdGoods/${ID}`, data, {params:queryParams})
      .toPromise()
      .then(res => res as any[])
  }

  GetProductForSO() {
    let corp_id = localStorage.getItem('corp_id')
    let queryParams = new HttpParams().append('corp_id', corp_id);
    return this.http
      .get(`${this.ConfigServerService.URL_API}/Goods/GetGoodsOrders`, {params:queryParams})
      .toPromise()
      .then(res => res as any[])
  }

  Upload(data) {
    let corp_id = localStorage.getItem('corp_id')
    let queryParams = new HttpParams().append('corp_id', corp_id);
    return this.http
      .post(`${this.ConfigServerService.URL_API}/Goods/Upload`, data, {params:queryParams})
      .toPromise()
      .then(res => res as any[])
  }
}
