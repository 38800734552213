import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HeaderInterceptorService {

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const authReq = request.clone({
      headers: new HttpHeaders({
        // 'Content-Type': 'application/json; multipart/form-data ',
        'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
      })
    });
    return next.handle(authReq);
  }
}
