import { Component, OnInit } from '@angular/core';
import { CustomerService } from '../../../services/customer.service'
import { DynamicDialogRef } from 'primeng/api';

@Component({
  selector: 'app-search-customer',
  templateUrl: './search-customer.component.html',
  styleUrls: ['./search-customer.component.css']
})
export class SearchCustomerComponent implements OnInit {

  constructor(
    public CustomerService: CustomerService,
    public ref: DynamicDialogRef,
  ) { }

  loading: boolean;
  listItem: any[];

  Cols: any[] = [
    { field: 'cust_code', header: '' },
    { field: 'cust_shopname', header: '' },
    { field: 'cust_adr_tel', header: '' },
  ]

  ngOnInit() {
    this.loading = true;
    this.onLoadProdicts();
  }

  onLoadProdicts() {
    this.CustomerService.GetCustomer().then(
      cust => {
        this.listItem = cust;
        this.loading = false;
      });
  }

  onSelect(items) {
    this.ref.close(items);
  }

  onSelectdbc(items) {
    this.ref.close(items);
  }

}
