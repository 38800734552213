import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ConfigServerService } from './config/config-server.service'


@Injectable({
  providedIn: 'root'
})
export class EmployeeService {


  constructor(
    private http: HttpClient,
    private ConfigServerService: ConfigServerService
  ) { }

  GetEmployee() {
    let corp_id = localStorage.getItem('corp_id')
    let queryParams = new HttpParams().append('corp_id', corp_id);
    return this.http
      .get(`${this.ConfigServerService.URL_API}/Employee/GetEmployee`, {params:queryParams})
      .toPromise()
      .then(res => res as any[])
  }

  GetEmployeeByID(ID) {
    let corp_id = localStorage.getItem('corp_id')
    let queryParams = new HttpParams().append('corp_id', corp_id);
    return this.http
      .get(`${this.ConfigServerService.URL_API}/Employee/GetEmployeeby/${ID}`, {params:queryParams})
      .toPromise()
      .then(res => res as any[])
  }

  PutEmployee(ID: number, data) {
    let corp_id = localStorage.getItem('corp_id')
    let queryParams = new HttpParams().append('corp_id', corp_id);
    return this.http
      .put(`${this.ConfigServerService.URL_API}/Employee/UpdEmployee/${ID}`, data, {params:queryParams})
      .toPromise()
      .then(res => res as any[])
  }

  PostEmployee(data) {
    let corp_id = localStorage.getItem('corp_id')
    let queryParams = new HttpParams().append('corp_id', corp_id);
    return this.http
      .post(`${this.ConfigServerService.URL_API}/Employee/InsEmployee`, data, {params:queryParams})
      .toPromise()
      .then(res => res as any[])
  }

  DeleteEmployee(ID: number) {
    let corp_id = localStorage.getItem('corp_id')
    let queryParams = new HttpParams().append('corp_id', corp_id);
    return this.http
      .delete(`${this.ConfigServerService.URL_API}/Employee/DelEmployee/${ID}`, {params:queryParams})
      .toPromise()
      .then(res => res as any[])
  }
}
