import { conf } from './../../common-config';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { AuthenService } from '../../services/authen.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers: [MessageService]
})
export class LoginComponent implements OnInit {

  returnURL: string;

  constructor(
    private FormBuilder: FormBuilder,
    private AuthenService: AuthenService,
    private Router: Router,
    private ActivatedRoute: ActivatedRoute,
    private MessageService: MessageService
  ) {
    this.ActivatedRoute.params.forEach(params => {
      this.returnURL = params.returnURL || `/Home`;
    });
  }

  btn_login: string = 'เข้าสู่ระบบ'

  ngOnInit() {
    this.initFrom();
  }

  btn_disable: boolean = false;
  onLogin() {

    this.submitted = true;
    if (this.formlogin.invalid) {
      return
    }

    this.btn_login = 'กำลังเข้าสู่ระบบ..'
    this.btn_disable = true;
    this.AuthenService.UserAuthen(this.formlogin.value).then(
      res => {
        if (res['message'] == "data is empty.") {
          this.MessageService.add({ severity: 'warn', summary: 'Username หรือ Password ไม่ถูกต้อง', detail: '' });
          setTimeout(() => {
            this.btn_login = 'เข้าสู่ระบบ';
            this.btn_disable = false;
          }, 1500);
          return;
        }
        let _token = res['token']
        let empid = res['data']['empid']
        let emp_name = res['data']['emp_name']
        let corp_id = res['data']['corp_id']
        this.AuthenService.setAuthenticated(_token, empid, emp_name, corp_id)
        // this.MessageService.add({ severity: 'success', summary: 'กำลังเข้าสู่ระบบ...', detail: '' });
        setTimeout(() => {
          this.Router.navigateByUrl(this.returnURL)
        }, 1500);
      }
    ).catch(
      res => {
        this.btn_login = 'เข้าสู่ระบบ'
        this.btn_disable = false;
        console.log(res)
        this.MessageService.add({ severity: 'error', summary: conf.ErrorMsg, detail: '' });
      }
    )
  }

  // convenience getter for easy access to form fields
  get f() { return this.formlogin.controls; }
  submitted = false;
  formlogin: FormGroup;
  initFrom() {
    this.formlogin = this.FormBuilder.group({
      'UserName': new FormControl('', Validators.required),
      'Password': new FormControl('', Validators.required),
    })
  }
}
