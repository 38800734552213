import { Router, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { ConfigServerService } from './config/config-server.service'
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AuthenService {

  private accessKey = 'accessToken';
  private emp_id = 'emp_id';
  private emp_name = 'emp_name';
  private corp_id = 'corp_id';
  returnURL: string;

  constructor(
    private http: HttpClient,
    private ConfigServerService: ConfigServerService,
    private router: Router,
  ) {
  }
  // กำหนดค่า access token ไว้ในความจำ browser
  setAuthenticated(accessToken: string, emp: string, emp_name: string, corp_id: string): void {
    localStorage.setItem(this.emp_id, emp);
    localStorage.setItem(this.accessKey, accessToken);
    localStorage.setItem(this.emp_name, emp_name);
    localStorage.setItem(this.corp_id, corp_id);
  }

  getemp_name(): string {
    return localStorage.getItem(this.emp_name)
  }

  // ดึงค่า access token ในความจำ browser ออกมา
  getAuthenticated(): string {
    return localStorage.getItem(this.accessKey)
  }

  // ดึงค่า Eemp
  geEemp_id(): number {
    return Number(localStorage.getItem(this.emp_id))
  }

  // ลบค่า access token ที่อยู่ในความจำ browser
  clearAuthenticated(): void {
    localStorage.removeItem(this.accessKey);
    localStorage.removeItem(this.emp_id);
    localStorage.removeItem(this.emp_name);
    localStorage.removeItem(this.corp_id);
    this._IUser = null;
  }

  logoutService(): void {
    this.clearAuthenticated();
    // alert("Session หมดอายุ\nกรุณาเข้าสู่ระบบอีกครั้งเพื่อต่ออายุ session");
    setTimeout(() => {
      this.router.navigate(['/login', { returnURL: this.router.routerState.snapshot.url }]);
    }, 100);
  }

  UserAuthen(data) {
    return this.http
      .post(`${this.ConfigServerService.URL_API}/Login/Authen`, data)
      .toPromise()
      .then(res => res as any[])
  }

  getIU() {
    return this._IUser
  }

  setIU(IUser) {
    this._IUser = IUser;

  }
  private _IUser: IUserLogin
}

export interface IUserLogin {
  accp_id: number;
  empl_id: number;
  username: string;
  customer_menu: number;
  employee_menu: number;
  good_menu: number;
  promotion_menu: number;
  coupon_menu: number;
  gift_menu: number;
  pricelevel_menu: number;
  invoice_menu: number;
  payment_menu: number;
  quotation_menu: number;
  customer_m_menu: number;
  saleorder_menu: number;
  saleorder_m_menu: number;
  reqdiscount_menu: number;
  confpayment_menu: number;
  shipment_menu: number;
  permission_menu: number;
  payment_report: number;
  saleorder_report: number;
  salearea_report: number;
  reqdiscount_report: number;
  btn_editprice: number;

  emp_code: string;
  emp_name: string;
  emp_tel: string;
}
