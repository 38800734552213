import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './pages/Home/home.component';
import { LoginComponent } from './pages/login/login.component';
import { AuthenticationGuard } from './guards/authentication.guard';
import { UnauthenticationGuard } from './guards/unauthentication.guard';

import { ReportComponent } from './pages/report/report.component';

const routes: Routes = [

  { path: 'Home', component: HomeComponent, canActivate: [AuthenticationGuard] },
  { path: 'login', component: LoginComponent, canActivate: [UnauthenticationGuard] },
  { path: 'Pre_Report/:search', component: ReportComponent, canActivate: [AuthenticationGuard] },
  { path: 'MasterData', loadChildren: './Module/master-data/master-data.module#MasterDataModule', canActivate: [AuthenticationGuard] },
  { path: 'Sale', loadChildren: './Module/sale/sale.module#SaleModule', canActivate: [AuthenticationGuard] },
  { path: 'Admin', loadChildren: './Module/admin/admin.module#AdminModule', canActivate: [AuthenticationGuard] },
  { path: 'Report', loadChildren: './Module/report/report.module#ReportModule', canActivate: [AuthenticationGuard] },
  { path: '', redirectTo: 'Home', pathMatch: 'full' },
  { path: '**', redirectTo: 'Home', pathMatch: 'full' },
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
