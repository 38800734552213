import { Component,  } from '@angular/core';
import { ContentComponent } from '../content/content.component'
import { AuthenService } from '../../services/authen.service';


@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.css']
})
export class TopbarComponent  {

  constructor(
    public app: ContentComponent,
    private AuthenService: AuthenService
  ) { }

  emp_name: string = this.AuthenService.getemp_name()
}
