import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ConfigServerService } from './config/config-server.service'

@Injectable({
  providedIn: 'root'
})
export class CustomerGroupService {

  constructor(
    private http: HttpClient,
    private ConfigServerService: ConfigServerService
  ) { }

  GetCustomerGroup() {
    let corp_id = localStorage.getItem('corp_id')
    let queryParams = new HttpParams().append('corp_id', corp_id);
    return this.http
      .get(`${this.ConfigServerService.URL_API}/CustomerGroup/getCustomerGroup`, {params:queryParams})
      .toPromise()
      .then(res => res as any[])
  }

}
